import React from "react";
import PageWrapper from "../components/PageWrapper";
import Grid from "../components/grid";

export default function Error() {
  return (
      <PageWrapper>
          <Grid  container maxWidth="lg" justifyContent="center" alignItems="center" >
                 <div className="error-wrapper">
                     <img src="/images/error.png" alt="Error" />
                     <div className="error-text-wrapper">
                         <h2>Vous avez pris un mauvais virage</h2>
                         <p>La page que vous recherchez a peut-être été supprimée, son nom a changé ou est temporairement indisponible.</p>
                     </div>
                     <a href="/">Retourner à l’accueil</a>
                 </div>
          </Grid>
          <img className="reassurance" src="/images/reassurance-bg.svg"/>
      </PageWrapper>
  );
}
