import axios from "axios";

const setAccessToken = async () => {
  try {
    const response = await axios.post("https://apigateway.lagentx.tech/auth");
    const tokenData = response.data;
    if (
        !tokenData ||
        !tokenData.access_token ||
        tokenData.access_token.toLowerCase() === "session"
    ) {
      throw Error("Invalid Token");
    }

    // Calculate expiration time
    const issuedAt = parseInt(tokenData.issued_at);

    const expirationDate = new Date(issuedAt + 604800000);

    document.cookie =
        "tokenData=" +
        JSON.stringify(tokenData.access_token) +
        "; expires=" +
        expirationDate.toUTCString() +
        "; path=/";
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
export default setAccessToken;