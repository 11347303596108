import React from "react";
import Head from "../components/head";
import PageWrapper from "../components/PageWrapper";
import Grid from "../components/grid";
import FormBuy from "../components/formBuy";
import CardCar from "../components/cardCar";
import PaginationItems from "../components/Pagination";

export default function BuyOthers() {
  const cars = new Array(12).fill({
    name: "Volkswagen Polo",
    description: "IV 200 AMG LINE 7G-DCT",
    price: "17 990",
    location: "359€/mois",
    img: "images/car_2.jpeg",
  });
  return (
    <div className="page-buy">
      <PageWrapper>
        <Head orange>J’achète mon véhicule avec Chasseurs d’Autos</Head>
        <Grid
          container
          maxWidth="lg"
          sx={{
            my: {
              md: 14,
            },
          }}
        >
          <Grid
            xs={12}
            md={4}
            sx={{
              mt: {
                md: 0,
              },
            }}
          >
            <FormBuy orange />
          </Grid>
          <Grid xs={12} md={8}>
            <Grid container>
              {cars.map((car, k) => (
                <Grid
                  key={k}
                  xs={12}
                  md={4}
                  sx={{
                    py: {
                      md: 1.5,
                    },
                    px: {
                      sm: 2,
                      md: 1.5,
                    },
                  }}
                >
                  <CardCar
                    id={k}
                    type="car-2"
                    name={car.name}
                    description={car.description}
                    price={car.price}
                    location={car.location}
                    img={car.img}
                    orange
                  />
                </Grid>
              ))}
            </Grid>
            <PaginationItems itemsPerPage={12} items={[]} />
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
