import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import { StyledEngineProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import HomePage from "./routes/home";
import GuidPage from "./routes/guid";
import AboutPage from "./routes/about";
import BuyPage from "./routes/buy";
import BuyOthersPage from "./routes/buy-others";
import SellPage from "./routes/sell";
import ProductPage from "./routes/product";
import ProductBuyPage from "./routes/product-buy";
import ProductBuyFormPage from "./routes/product-buy-form";
import ErrorPage from "./routes/error";
import PrivacyPolicy from "./routes/privacy-policy";
import LegalNotice from  "./routes/legal-notice";
import CookiePolicy from "./routes/cookie-policy";
import TermsConditionsSale from "./routes/terms-conditions-sale";
import TermsConditions from "./routes/terms-conditions";
import LoadingOverlay from "./components/Loading";

function App({
  state: {
    app: { loading },
  },
}: any) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/acheter",
      element: <BuyPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/acheter-others",
      element: <BuyOthersPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/vendre",
      element: <SellPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/qui-sommes-nous",
      element: <AboutPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/comment-ca-marche",
      element: <GuidPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/produit",
      element: <ProductPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/financement",
      element: <ProductBuyPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/formulaire-financement",
      element: <ProductBuyFormPage />,
      errorElement: <ErrorPage />,
    },
    {

      path: "/politique-de-confidentialite",
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/mentions-legales",
      element: <LegalNotice />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/politique-de-cookies",
      element: <CookiePolicy />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/conditions-generales-vente",
      element: <TermsConditionsSale />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/conditions-generales-utilisation",
      element: <TermsConditions />,
      errorElement: <ErrorPage />
    },
    {
      path: "/error",
      element: <ErrorPage />,
      errorElement: <h1>Error</h1>,
    },
  ]);
  return (
    <>
      <StyledEngineProvider injectFirst>
        <RouterProvider router={router} />
        {loading && <LoadingOverlay />}
        {/* Your component tree. Now you can override MUI's styles. */}
      </StyledEngineProvider>
    </>
  );
}

function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(App);
