import React from "react";
import Icon from "./Icon";

interface Props {
  icon?: string;
  type?: string;
  title?: string;
  description?: string;
  orange?: boolean;
  onPress?: () => void;
}

export default function CardBuy({
                                  type,
                                  icon,
                                  title = "name here",
                                  description = "description here",
                                  orange,
                                  onPress,
                                }: Props) {
  let cardTypeClass = "";
  if (type === "title") {
    cardTypeClass = "card-title";
  } else if (type === "product") {
    cardTypeClass = "card-product";
  } else {
    cardTypeClass = "card-buy";
  }

  let className = "card " + cardTypeClass;
  if (orange) {
    className += " card-orange";
  }

  return (
      <div className={className} onClick={onPress}>
        <div className="details">
          <div className="right">
            <div className="icon-wrapper">
              <Icon icon={icon ? icon : "camera"} size={25} color="white" />
            </div>
          </div>
          <div className="left">
            <p className="name">{title}</p>
            <p className="description">{description}</p>
          </div>
        </div>
      </div>
  );
}