import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function CookiePolicy() {
    return (
        <div>
            <PageWrapper>
                <Helmet>
                    <title>Chasseurs d’Autos - Mentions légales de Chasseurs d’Autos</title>
                    <meta
                        name="description"
                        content="Découvrez notre politique des cookies et comment nous utilisons ces derniers pour améliorer votre expérience sur notre site web"
                    />
                    <link
                        rel="canonical"
                        href="https://chasseursdautos.com/politique-de-cookies"
                    />
                </Helmet>
                <Head>
                    <b>POLITIQUE DES COOKIES</b>
                </Head>
                <Grid container maxWidth="lg">
                    <div className="legalWrapper">
                        <div>
                            <h3>QU&apos;EST-CE QU&apos;UN COOKIE ?</h3>
                            <hr/>
                        </div>
                        <div>
                            <p>Lors de votre navigation sur notre Site des cookies, pixels et autres traceurs (ci-après désignés ensemble les « <strong>Cookies</strong> ») sont déposés sur votre navigateur.</p>
                            <p>Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à vos données de navigation et/ ou à des données à caractère personnel vous concernant.</p>
                            <p>Cette page vous permet de mieux comprendre comment fonctionnent les cookies et comment utiliser les outils actuels afin de les paramétrer.</p>
                        </div>
                        <div>
                            <h3>IDENTIFICATION DES COOKIES</h3>
                            <hr/>
                        </div>
                        <div>
                            <p><span>Cookies techniques et fonctionnels</span></p>
                            <p>Les Cookies techniques et fonctionnels sont nécessaires au bon fonctionnement du Site et pour vous fournir nos services. Ils sont utilisés tout au long de votre navigation, aﬁn de la faciliter et d’exécuter certaines fonctions.</p>
                            <p>Un Cookie technique peut par exemple être utilisé pour mémoriser vos réponses renseignées dans un formulaire ou encore vos préférences s’agissant de la langue ou de la présentation du Site, lorsque de telles options sont disponibles. Sans ces Cookies vous ne pourrez utiliser normalement le site, nous vous déconseillons de les supprimer.</p>
                            <p>Nous utilisons les Cookies techniques et fonctionnels suivants :</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Nom du Cookie</strong>
                                        </td>
                                        <td>
                                            <strong>Fonction du Cookie</strong>
                                        </td>
                                        <td>
                                            <strong>Durée de conservation</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong><em>Non applicable</em></strong>
                                        </td>
                                        <td>
                                            <strong><em>Non applicable</em></strong>
                                        </td>
                                        <td>
                                            <strong><em>Non applicable</em></strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p><span>Cookies publicitaires</span></p>
                            <p>Les Cookies publicitaires peuvent être créés non seulement par notre Site mais également par d’autres sites internet et applications diﬀusant des publicités, annonces, widgets ou autres éléments sur la page aﬃchée.</p>
                            <p>Ces Cookies peuvent notamment servir à personnaliser et mesurer l&apos;efcacité de la publicité ou encore à réaliser du ciblage publicitaire.</p>
                            <p>Nous utilisons les Cookies publicitaires suivants :</p>
                            <p>Nous utilisons les Cookies analytiques suivants :</p>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <strong>Nom du Cookie</strong>
                                    </td>
                                    <td>
                                        <strong>Fonction du Cookie</strong>
                                    </td>
                                    <td>
                                        <strong>Durée de conservation</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p><span>Cookies de personnalisation de contenu</span></p>
                            <p>Les Cookies de personnalisation de contenu nous permettent de vous proposer les contenus les plus susceptibles de correspondre à vos centres d’intérêt au regard de votre proﬁl de navigation. Votre proﬁl de navigation est établi en fonction des contenus que vous déjà avez consultés.</p>
                            <p>Nous utilisons les Cookies de personnalisation de contenu suivants :</p>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <strong>Nom du Cookie</strong>
                                    </td>
                                    <td>
                                        <strong>Fonction du Cookie</strong>
                                    </td>
                                    <td>
                                        <strong>Durée de conservation</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                    <td>
                                        <strong><em>Non applicable</em></strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p><span>Cookies de réseaux sociaux</span></p>
                            <p>Les Cookies de réseaux sociaux vous permettent de partager des contenus de notre Site sur les réseaux sociaux et de faire connaître, sur ces réseaux, votre opinion ou votre consultation de nos Services en cliquant notamment sur les liens « j’aime » et « partager ».</p>
                            <p>Ces Cookies peuvent également permettre de tracer la navigation des utilisateurs sur le Site.</p>
                            <p>Nous utilisons les Cookies de réseaux sociaux suivants :</p>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Nom du Cookie</strong>
                                </td>
                                <td>
                                    <strong>Fonction du Cookie</strong>
                                </td>
                                <td>
                                    <strong>Durée de conservation</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                            </tr>
                            </tbody>
                            <p><span>Cookies analytiques</span></p>
                            <p>Ces Cookies nous permettent de mesurer le nombre de visites, le nombre de pages vues et l’activité des utilisateurs. Ils peuvent le cas échéant collecter votre adresse IP pour déterminer la ville depuis laquelle vous vous connectez. Les Cookies analytiques nous permettent de générer des statistiques de fréquentation et de navigation de notre Site aﬁn d’améliorer nos performances. </p>
                            <p>Les Cookies utilisés permettent également d’identiﬁer les problèmes de navigation et à terme, de les régler.</p>
                            <p>Nous utilisons les Cookies analytiques suivants :</p>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Nom du Cookie</strong>
                                </td>
                                <td>
                                    <strong>Fonction du Cookie</strong>
                                </td>
                                <td>
                                    <strong>Durée de conservation</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                                <td>
                                    <strong><em>Non applicable</em></strong>
                                </td>
                            </tr>
                            </tbody>
                        </div>
                        <div>
                            <p></p>
                            <h3>VOS PREFERENCES EN MATIERE DE COOKIES</h3>
                            <hr/>
                        </div>
                        <div>
                            <p><span>Cookies pouvant être déposés sans consentement</span></p>
                        </div>
                        <div>
                            <p>Certains Cookies ne nécessitent pas votre consentement, c’est le cas des :</p>
                            <ul>
                                <li>Cookies techniques et fonctionnels qui sont nécessaires au fonctionnement du Site; et</li>
                                <li>De certains Cookies de mesure d’audience ou des Cookies qui permettent de tester des versions diﬀérentes du Site à des ﬁns d’optimisation des choix éditoriaux.</li>
                            </ul>
                        </div>
                        <div>
                            <p><span>L’acceptation ou le refus des Cookies soumis à votre consentement express</span></p>
                        </div>
                        <div>
                            <p>Tous les autres Cookies nécessitent votre consentement. Il s’agit des Cookies publicitaires, de réseaux sociaux, de personnalisation de contenu et de certains Cookies d’analyse d’audience. Vous pouvez choisir librement d’accepter ou de refuser l’utilisation de ces Cookies.</p>
                            <p>Vous pouvez accepter ou refuser ces Cookies lors de votre première navigation sur le Site.</p>
                            <p>Vos choix d’accepter ou de refuser les cookies seront conservés pendant une durée de six (6) mois.</p>
                            <p>Vous êtes libre de retirer votre consentement et plus généralement de modiﬁer vos préférences à tout moment, via le bandeau des cookies.</p>
                            <p><span>Le paramétrage de votre navigateus</span></p>
                            <p>La plupart des navigateurs acceptent les Cookies par défaut. Cependant, vous pouvez décider de bloquer ces cookies ou demander à votre navigateur de vous avertir lorsqu’un site tente d’implémenter un cookie sur votre terminal.</p>
                            <p>Attention, certaines fonctionnalités du Site peuvent ne plus fonctionner.</p>
                            <p>Il est également possible de paramétrer votre navigateur aﬁn qu’il accepte ou refuse certains Cookies.</p>
                            <p>La configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de Cookies.</p>
                            <p>Chaque navigateur propose des modalités de conﬁguration diﬀérentes :</p>
                            <p>Pour Edge : rendez-vous dans le menu « Paramètres », puis « Option Internet ». Cliquez sur « Conﬁdentialité » puis sur « Paramètres de conﬁdentialité avancés » ;</p>
                            <p>Pour Chrome : rendez-vous dans le menu « Paramètres » puis cliquez sur « Conﬁdentialité et Sécurité ». Cliquez sur « Autorisation » et « Cookies et données du sites » ;</p>
                            <p>Pour Safari : rendez-vous dans l’application « Réglages » puis allez sur Safari. Cliquez sur l’onglet « Conﬁdentialité et Sécurité ». Vous pouvez désormais choisir de bloquer tous les Cookies.</p>
                            <p><span>Identiﬁants publicitaires</span></p>
                            <p>Des données personnelles vous concernant peuvent être collectées en lien avec votre identiﬁant publicitaire.</p>
                            <p>Chaque téléphone mobile possède un identiﬁant publicitaire qui lui est propre et qui permet aux annonceurs de diﬀuser de la publicité ciblée.</p>
                            <p>Android et Apple permettent aux mobinautes, dans les réglages, de maîtriser leur vie privée ce qui rend impossible le lien entre leur historique publicitaire et leurs prochaines navigations.</p>
                            <p>Vous pouvez désactiver et réinitialiser à tout moment votre identiﬁant publicitaire, si vous ne souhaitez plus recevoir de publicités ciblées :  </p>
                            <p>Sur iOS : allez dans les  &quot;Réglages&quot;, puis dans  &quot;Conﬁdentialité&quot;, puis dans &quot;Publicité&quot; puis dans  &quot;Réinitialiser l&apos;identiﬁant de publicité&quot;;</p>
                            <p>Sur Android : allez dans &quot;Paramètres Google&quot; (ou &quot;Paramètres&quot; puis &quot;Google&quot;), puis dans &quot;Annonces&quot;.</p>
                            <p>Vous recevrez probablement la même quantité de publicités mais elles seront moins pertinentes.</p>
                        </div>
                        <div>
                            <h3>EN SAVOIR PLUS SUR LES COOKIES</h3>
                            <hr/>
                        </div>
                        <div>
                            <p>Pour plus d’information sur les cookies, vous pouvez vous rendre sur le site de la CNIL, à cette adresse : <a href="https://www.cnil.fr/vos-droits/vos-traces/les-cookies/">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</a></p>
                        </div>
                    </div>
                </Grid>
            </PageWrapper>
        </div>
    );
}
