import React from "react";
import { useLocation } from "react-router";
import PageWrapper from "../components/PageWrapper";
import BlockProductBuyForm from "../components/blockProductBuyForm";

export default function ProductBuyForm() {
  const { state } = useLocation();
  const productData = state?.productData || {};
  const images = state?.images || [];
  const simulationData = state?.simulationData || {};

  if (!productData || !productData.Name) return <h1>No data</h1>;

  if (!simulationData || !simulationData.duration) {
    return <p>No simulation data</p>;
  }

  return (
    <div className="page-Product">
      <PageWrapper>
        <BlockProductBuyForm
          productData={productData}
          images={images}
          simulationData={simulationData}
          activeForm={state?.activeForm}
          version={state?.version}
        />
      </PageWrapper>
    </div>
  );
}
