import React, { useEffect, useState } from "react";
import Button from "./button";
import Select from "react-select";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../redux/actions/appActions";
import { useForm, Controller } from "react-hook-form";
import Slider from "@mui/material/Slider";
import CardBuy from "./cardBuy";
import * as yup from "yup";
import {
  radioUncheck,
  sliderLabelText,
  parseAndFilterPrixTTC,
  parseAndFilterKmCompteur
} from "../shared/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "../shared/axiosInstance";
import {AxiosError} from "axios";
import setAccessToken from "../shared/setAccessToken";
const schema = yup.object().shape({
  marque: yup.object().required(),
  prix: yup
      .mixed()
      .test("Doit être un nombre", "Doit être un nombre", function (value) {
        if (value === "") {
          return true; // Empty string is valid
        }
        return yup.number().isValidSync(value); // Validate as number
      }),
  mileage: yup
      .mixed()
      .test("Doit être un nombre", "Doit être un nombre", function (value) {
        if (value === "") {
          return true;
        }

        return yup.number().isValidSync(value); // Validate as number
      }),
});

const FormBuy = ({
                   orange,
                   formData,
                   handleFormData,
                   marqueOptions,
                   modeleOptions = {},
                   carburantOptions,
                   gearboxesOptions,
                   dispatch,
                   handleSearchedChange,
                 }: {
  orange?: boolean;
  formData?: any;
  handleFormData?: any;
  marqueOptions?: Array<string>;
  modeleOptions?: any;
  carburantOptions?: any;
  gearboxesOptions?: any;
  state?: any;
  dispatch?: any;
  handleSearchedChange?: any
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchMarque = watch("marque");

  const [isShown, setIsShown] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  const [modeleInnerOptions, setModuleInnerOptions] = useState<any>(null);
      useState<any>(null);

  const carburantsInnerOptions = [
    { value: "GO", label: "GO" },
    { value: "GH", label: "GH" },
    { value: "GL", label: "GL" },
    { value: "ES", label: "ES" },
    { value: "EH", label: "EH" },
    { value: "EE", label: "EE" },
    { value: "E", label: "E" },
    { value: "EL", label: "EL" },
  ];

  const gearBoxInnerOptions = [
    { value: "Automatique", label: "Automatique" },
    { value: "Manuelle", label: "Manuelle" }
  ];

  useEffect(() => {
    radioUncheck(getValues, setValue);
  }, [watch]);

  useEffect(() => {
    const fetchData = async () => {
      setValue("model", null);
      if (watchMarque) {
        try {
          const modelsResponse = await axiosInstance().get(`/models/${watchMarque.value}`);
          let modelsData = modelsResponse.data;
          modelsData = modelsData.map(model => ({
            value: model,
            label: model
          }));
          setModuleInnerOptions(modelsData || []);
        } catch (error) {
          console.error('Error fetching models:', error);
        }
      }
    };
    fetchData();
  }, [watchMarque]);


  useEffect(() => {
    if (formData) {
      if(handleSearchedChange){
        handleSearchedChange();
        setCancelButton(true)
      }
      dispatch(startLoading());
      setValue("marque", formData.marque);
      setValue("mileage", formData.miles?.value);
      setValue("monthly", formData.monthly);
      let searchQuery = `/cars?page=0&filter_Marque__c=${formData.marque.value}&type_Marque__c=String`;
      if (formData?.monthly) {
        if (formData.monthly !== 1000) {
          searchQuery += `&type_LoyerMensuel__c=Int&filter_LoyerMensuel__c<~=${formData.monthly}`;
        }
      }
      if (formData.miles?.value){
        searchQuery = searchQuery.replace("page","size");
      }
      const fetchProducts = async () => {
        try {
          const { data } = await axiosInstance().get(searchQuery);
          let count = data.count;
          let newData = data.cars;
          if(formData.miles?.value){
            newData = parseAndFilterKmCompteur(newData,formData.miles?.value)
            count = newData.length
          }
          handleFormData(newData,count,searchQuery);
          dispatch(stopLoading());
        } catch (err) {
          if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToken();
            fetchProducts();
          }
        }
        dispatch(stopLoading());
      };
      fetchProducts();
    }
  }, []);

  const onSubmit = (sdata: any) => {
    if(handleSearchedChange){
      handleSearchedChange();
      setCancelButton(true)
    }
    dispatch(startLoading());
    let searchQuery = "/cars?page=0";
    if (sdata.marque) {
      searchQuery += `&filter_Marque__c=${sdata.marque.value}&type_Marque__c=String`;
    }
    if (sdata.model) {
      searchQuery += `&filter_Modele__c=${sdata.model.value}&type_Modele__c=String`;
    }
    if (sdata.fuel) {
      searchQuery += `&filter_Energie__c=${sdata.fuel.value}&type_Energie__c=String`;
    }
    if (sdata.gearbox) {
      searchQuery += `&filter_TypeBoite__c=${sdata.gearbox.value}&type_TypeBoite__c=String`;
    }
    if (sdata.monthly) {
      if (sdata.monthly !== 0) {
        searchQuery += `&type_LoyerMensuel__c=Int&filter_LoyerMensuel__c<~=${sdata.monthly}`;
      }
    }
    if(sdata.prix){
      searchQuery = searchQuery.replace("page","size");
    }
    if(sdata.mileage){
      searchQuery = searchQuery.replace("page","size");
    }
    const fetchProducts = async () => {
      try {
        const { data } = await axiosInstance().get(searchQuery);
        let count = data.count;
        let newData = data.cars;
        if(sdata.prix){
          newData = parseAndFilterPrixTTC(newData,sdata.prix)
          count = newData.length;
        }
        if(sdata.mileage){
          newData = parseAndFilterKmCompteur(newData,sdata.mileage)
          count = newData.length;
        }
        handleFormData(newData,count,searchQuery);
        dispatch(stopLoading());
      } catch (err) {
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToken();
          return
        }
        console.log("Error:", (err as Error).message);
      }
    };
    fetchProducts();
  };
  const handelCancel = ()=> {
    setCancelButton(false);
    handleFormData(null,null,null,true)
    setValue("marque", null);
    setValue("mileage", "");
    setValue("monthly", 0);
  }

  return (
      <div className={`form-buy-2${isShown ? " show" : ""}`}>
        <CardBuy
            type="title"
            title="Affiner"
            description="votre recherche"
            orange={orange}
            onPress={() => setIsShown(!isShown)}
        />
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form form-buy form-buy-2"
            id="form-buy"
        >
          <div className={`form-content-wrapper`}>
            <div className="form-controllers">
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="monthly">
                  Mensualité
                </label>
                <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={0}
                    render={({field: {onChange, value, ref}}) => (
                        <div
                            className="form-control form-control-slider"
                            id="monthly"
                            ref={ref}
                        >
                          <Slider
                              getAriaLabel={() => "Budget"}
                              value={value}
                              min={0}
                              max={1000}
                              step={100}
                              onChange={(e, newValue) => {
                                onChange(e);
                                setValue("monthly", newValue, {shouldValidate: true});
                              }}
                              size="small"
                              valueLabelDisplay="on"
                              valueLabelFormat={(v) => sliderLabelText(v, "€")}
                          />
                        </div>
                    )}
                    name="monthly"
                />

                {errors["monthly"]?.message && (
                    <p className="error">
                      {(errors["monthly"] as { message: string }).message}
                    </p>
                )}
              </div>
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="marque">
                  Marque
                </label>
                <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <Select
                            {...register("marque")}
                            className="form-control form-control-select"
                            options={marqueOptions}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            placeholder="Choisissez la marque"
                            id="marque"
                        />
                    )}
                    name="marque"
                />
                {(() => {
                  if (errors["marque"]?.message) {
                    return (
                        <p className="error">
                          {(errors["marque"] as { message: string }).message}
                        </p>
                    );
                  }
                })()}
              </div>
              {modeleInnerOptions && (
                  <div className="form-control-wrapper">
                    <label className="form-label" htmlFor="model">
                      Modèle
                    </label>
                    <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({field: {onChange, onBlur, value}}) => (
                            <Select
                                {...register("model")}
                                className="form-control form-control-select"
                                options={modeleInnerOptions}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                placeholder="Choisissez le modèle"
                                id="model"
                            />
                        )}
                        name="model"
                    />
                    {(() => {
                      if (errors["model"]?.message) {
                        return (
                            <p className="error">
                              {(errors["model"] as { message: string }).message}
                            </p>
                        );
                      }
                    })()}
                  </div>
              )}
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="fuel">
                  Carburant
                </label>
                <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <Select
                            {...register("fuel")}
                            className="form-control form-control-select"
                            options={carburantsInnerOptions}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={carburantsInnerOptions.find(option => option.value === value)}
                            placeholder="Choisissez le carbrant"
                            id="fuel"
                        />
                    )}
                    name="fuel"
                />
                {(() => {
                if (errors["fuel"]?.message) {
                  return (
                      <p className="error">
                        {(errors["fuel"] as { message: string }).message}
                      </p>
                  );
                }
              })()}
              </div>

              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="gearbox">
                  Boîte de vitesse
                </label>
                <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <Select
                            {...register("gearbox")}
                            className="form-control form-control-select"
                            options={gearBoxInnerOptions}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={gearBoxInnerOptions.find(option => option.value === value)}
                        />
                    )}
                    name="gearbox"
                />
                {(() => {
                  if (errors["gearbox"]?.message) {
                    return (
                        <p className="error">
                          {errors["gearbox"] &&
                              (errors["gearbox"] as { message: string }).message}
                        </p>
                    );
                  }
                })()}
              </div>
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="mileage">
                  Kilométrage max
                </label>
                <input
                    className="form-control"
                    placeholder="Kilométrage"
                    {...register("mileage")}
                />
                {(() => {
                  if (errors["mileage"]?.message) {
                    return (
                        <p className="error">
                          {(errors["mileage"] as { message: string }).message}
                        </p>
                    );
                  }
                })()}
              </div>
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="prix">
                  Prix maximum
                </label>
                <input
                    className="form-control"
                    placeholder="9060€"
                    {...register("prix")}
                />
                {(() => {
                  if (errors["prix"]?.message) {
                    return (
                        <p className="error">
                          {(errors["prix"] as { message: string }).message}
                        </p>
                    );
                  }
                })()}
              </div>
            </div>
            <div className="form-actions">
              <Button
                  text="Rechercher"
                  orange={orange}
                  onPress={handleSubmit(onSubmit)}
              />
            </div>
            {cancelButton ? <div className="form-actions">
              <Button
                  text="Cancel"
                  orange
                  onPress={handelCancel}
              />
            </div> : null}
          </div>
        </form>
      </div>
  );
};

function mapStateToProps(state: any) {
  return {state};
}

export default connect(mapStateToProps)(FormBuy);