import React from "react";
import Grid from "./grid";
interface Props {
  children: any;
  title: string;
  subtitle: string;
}

export default function BlockImageWrapperd({ children, title, subtitle }: Props) {
  return (
    <div className="block-image block-image-wrapperd">
      <Grid
        container
        maxWidth="lg"
        columnSpacing={{
          xs: 2,
        }}
        rowSpacing={{
          md: 8,
          xs: 3,
        }}
        sx={{
          my: {
            md: 12,
            xs: 8,
          },
          px: {
            xs: 2,
          },
        }}
        className="block-image-wrapper"
      >
        <Grid xs={12}>
          <h3 className="block-title">{title}</h3>
          <p className="block-subtitle">{subtitle}</p>
        </Grid>
        <Grid
          xs={12}
          sx={{
            my: "auto",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
