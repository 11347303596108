import axiosInstance from "./axiosInstance";
import setAccessToken from "./setAccessToken";
import { AxiosError } from "axios";

const fetchImages = async (cardId: string) => {
    try {
        const apiCall = await axiosInstance().get(
            `/images/getByIds/${cardId}`
        );
        const images = apiCall.data
            .flatMap((e: any) => e.images)
            .sort((a, b) => {
                if (a.isDefaultImage === b.isDefaultImage) {
                    return 0;
                }
                return a.isDefaultImage ? -1 : 1;
            })
            .map((c) => `https://apigateway.lagentx.tech/compressor/xet?url=${encodeURIComponent(c.imgURL)}&quality=${process.env.REACT_APP_SLIDESHOW_QUALITY}&slash=${process.env.REACT_APP_SLIDESHOW_SLASH}&cat=${process.env.REACT_APP_SLIDESHOW_CAT}`);
        return images;
    }
    catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToken();
            return;
        }
    }
};


const fetchThumbnails = async (cardId: string) => {
    try {
        const images:any = []
        const apiCallThumbnail = await axiosInstance().get(
            `/images/getDefaultByIds/${cardId}`
        );
        apiCallThumbnail.data.forEach((e)=>{
            const id = e.id
            let imageUrl = ""
            e.images.forEach(c=>{imageUrl = `https://apigateway.lagentx.tech/compressor/xet?url=${encodeURIComponent(c.imgURL)}&quality=${process.env.REACT_APP_THUMBNAIL_QUALITY}&slash=${process.env.REACT_APP_THUMBNAIL_SLASH}&cat=${process.env.REACT_APP_THUMBNAIL_CAT}`
            })
            images.push({
                "imageUrl" : imageUrl,
                "id" : id,
            })
        })
        const idArray:any = cardId.split(',');
        idArray.forEach((id) => {
            const exists = images.some((image) => image.id === id);
            if (!exists) {
                images.push({
                    "imageUrl" : "https://placehold.co/600x400?text=No+Image",
                    "id" : id,
                })
            }
        });
        return images
    } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToken();
            return;
        }
    }
};
export { fetchImages, fetchThumbnails };