import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Head from "../components/head";
import PageWrapper from "../components/PageWrapper";
import Grid from "../components/grid";
import FormBuy from "../components/formBuy";
import CardCar from "../components/cardCar";
import PaginationItems from "../components/Pagination";
import axiosInstance from "../shared/axiosInstance";
import Loading from "../components/Loading";
import {fetchThumbnails} from "../shared/APIs";
import {AxiosError} from "axios";
import setAccessToken from "../shared/setAccessToken";

export default function Buy() {
  const [productsData, setProductsData] = useState<any[] | null>(null);

  const [currentItems, setCurrentItems] = useState<Array<any>>([]);
  const [marques, setMarques] = useState<Array<any>>([]);
  const carburants = [];
  const gearboxes = [];
  const modeles = [];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [carImages, setCarImages] = useState<Array<any>>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [searched, setSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [oldPageNumber, setOldPageNumber] = useState(0);
  const [spec, setSpec] = useState(false);
  const [dataSized, SetDataSized] = useState<Array<any>>([]);
  const [forceRenderKey, setForceRenderKey] = useState("initial");
  const [forceRenderCancel, setForceRenderCancel] = useState("initial");


  const objValue = (value: string) => {
    return { value, label: value };
  };

  type ObjValue = {
    value: string;
    label: string;
  };

  type ModeleObjValue = {
    marque: string;
    modeles: Array<ObjValue | string>;
  };

  type CarburantObjectValue = {
    marque: string;
    carburants: Array<ObjValue | string>;
  };

  type GearBoxObjectValue = {
    marque: string;
    gearboxes: Array<ObjValue | string>;
  };

  const location = useLocation();
  const formData = location.state?.formData;

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        setIsLoading(true);
        const Marquesresponse = await axiosInstance().get('/brands');
        const dataMarques = Marquesresponse.data.map((v: any) => objValue(v));
        setMarques(dataMarques);
        setIsLoading(false);
      } catch (err) {
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToken();
          return;
        }
        setIsLoading(false);
      }
    };
    fetchBrands();
  }, []);

  const handleSearchedChange = () => {
    setSearched(true);
  };

  const currentItemsRender = async () => {
    try {
      if(!searched){
        const carsResponse = await axiosInstance().get(`/cars?page=${pageNumber}`);
        const carsData = carsResponse.data.cars;
        setCurrentItems(carsData);
      }
      if(searched){
        if(searchQuery != ""){
          const myUpdatedQuery = searchQuery.replace(`page=${oldPageNumber}`,`page=${pageNumber}`)
          setSearchQuery(myUpdatedQuery);
          const {data} = await axiosInstance().get(myUpdatedQuery);
          setCurrentItems(data.cars);
        }
      }
    }
    catch (err) {
      if ((err as AxiosError)?.response?.status === 401) {
        await setAccessToken();
        return;
      }
    }
  };

  const RenderCancel = async () =>{
    const carsResponse = await axiosInstance().get(`/cars?page=${pageNumber}`);
    const carsData = carsResponse.data.cars;
    setCurrentItems(carsData);
  }

  useEffect(() => {
    currentItemsRender();
  }, [pageNumber]);


  useEffect(() => {
      RenderCancel();
  }, [forceRenderCancel]);


  const fetchData = async () => {
    try {
      const countResponse = await axiosInstance().get("/count");
      const data = countResponse.data;
      setPageCount(Math.ceil(data.count/12));
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 401) {
        await setAccessToken();
        return;
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFormData = (data: any,count:any,search:any,cancelSearch:boolean) => {
   if(data != null && count != null && search != null){
     if(search.includes("size")){
       setSpec(true)
       setCurrentItems(data.slice(0,12));
       SetDataSized(data);
     }
     else {
       setProductsData(data);
       setCurrentItems(data);
       setPageCount(Math.ceil(count/12));
       setSpec(false)
     }
     setSearchQuery(search);
   }
   if(cancelSearch){
     setSpec(false)
     setSearched(false);
     setOldPageNumber(0);
     setPageNumber(0);
     setSearchQuery("");
     fetchData();
     setForceRenderCancel((prevKey) => (prevKey === "initial" ? "first" : "initial"));
   }
    setForceRenderKey((prevKey) => (prevKey === "initial" ? "first" : "initial"));
  };

  const cardsId = currentItems.map(e => e.Id).join(",");

  useEffect(() => {
    if (cardsId.length>0){
      fetchThumbnails(cardsId)
          .then((data) => {
            setCarImages(data);
          });
    }
  }, [cardsId]);

  const onPageChange = (event)=>{
    setOldPageNumber(pageNumber);
    setPageNumber(event.selected);
  }
  const onNewPageChange = (event)=>{
    const startIndex = ((event.selected+1) - 1) * 12;
    const endIndex = Math.min(startIndex + 12, dataSized.length);
    setCurrentItems(dataSized.slice(startIndex, endIndex));
  }
  currentItems.forEach(item => {
    let matchingImage : any;
    if(carImages){
      matchingImage = carImages.find(image => image.id === item.Id);
    }
    if (matchingImage) {
      item.img = matchingImage.imageUrl;
    }
  });
  if (isLoading) return <Loading />;
  return (
      <div className="page-buy">
        <PageWrapper>
          <Head light>J’achète mon véhicule avec Chasseurs d’Autos</Head>
          <Grid
              container
              maxWidth="lg"
              sx={{
                my: {
                  md: 14,
                },
                px: {
                  md: 1,
                },
              }}
              className="buy-filter"
          >
            <Grid
                xs={12}
                md={3.5}
                sx={{
                  mt: {
                    md: 0,
                  },
                }}
            >
              <FormBuy
                  formData={formData}
                  marqueOptions={marques}
                  modeleOptions={modeles}
                  carburantOptions={carburants}
                  gearboxesOptions={gearboxes}
                  handleFormData={handleFormData}
                  handleSearchedChange={handleSearchedChange}
              />
            </Grid>
            <Grid
                xs={12}
                md={8.5}
                sx={{
                  my: 0,
                }}
                id="car-items"
            >
              <Grid container>
                {currentItems &&
                    currentItems.map((car, k) => (
                        <Grid
                            key={k}
                            xs={12}
                            md={4}
                            sx={{
                              my: {
                                xs: 2,
                                md: 0,
                              },
                              mx: 0,
                              py: {
                                md: 1.5,
                              },
                              px: {
                                sm: 2,
                              },
                            }}
                        >
                          <CardCar
                              id={k}
                              productId={car.Id}
                              type="car-2"
                              name={car.Marque__c + " " + car.Modele__c}
                              description={car.Version__c}
                              price={car.PrixTTC__c || null}
                              location={car.LoyerMensuel__c}
                              img={car.img}
                              km={car.KmCompteur__c}
                              year={new Date(car.DateMEC__c).getFullYear()}
                          />
                        </Grid>
                    ))}
              </Grid>
              {spec ?  <PaginationItems
                  key={forceRenderKey}
                  itemsPerPage={12}
                  currentItemsRender={currentItems}
                  items={dataSized || []}
                  pageCount={Math.ceil(dataSized.length/12)}
                  onPageChange={onNewPageChange}
                  initialPage={0}
              /> :  <PaginationItems
                  key={forceRenderKey}
                  itemsPerPage={12}
                  currentItemsRender={currentItems}
                  items={productsData || []}
                  onPageChange={onPageChange}
                  pageCount = {pageCount}
                  initialPage={0}
              /> }
              {currentItems.length >= 1 && (
                  <p className="indecate">
                    *Un crédit vous engage et doit être remboursé, vérifiez vos
                    capacités de remboursement
                  </p>
              )}
              {currentItems.length <= 0 && (
                  <p className="message">pas de voiture trouvée !</p>
              )}
            </Grid>
          </Grid>
        </PageWrapper>
      </div>
  );
}