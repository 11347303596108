import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/configureStore";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const store = configureStore();


// Start Axeption

interface AxeptioSettings {
  clientId: string;
  cookiesVersion: string;
}

declare global {
  interface Window {
    axeptioSettings: AxeptioSettings;
  }
}

window.axeptioSettings = {
  clientId: "64786344ad7497646497b879",
  cookiesVersion: "chasseurs d'autos-fr",
} as AxeptioSettings;

((d: Document, s: string) => {
  const t = d.getElementsByTagName(s)[0] as HTMLElement;
  const e = d.createElement(s) as HTMLScriptElement;
  e.async = true;
  e.src = "//static.axept.io/sdk-slim.js";
  t.parentNode?.insertBefore(e, t);
})(document, "script");

// End Axeption

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1465,
      xl: 1536,
    },
  },
});
root.render(
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <App/>
      </ReduxProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
