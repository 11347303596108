import axios from "axios";
import { getBearerTokenFromCookie } from "./helpers";

const defaultBaseURL = "https://apigateway.lagentx.tech";

const createAxiosInstance = (includeBaseURL = true) => {
    const baseURL = includeBaseURL ? defaultBaseURL : "";

    const axiosInstance = axios.create({
        baseURL: baseURL
    });
    axiosInstance.interceptors.request.use(
         function (config) {
            const token = getBearerTokenFromCookie();
            if (token) {
                config.headers["Authorization"] = "Bearer " + token;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default createAxiosInstance;