import React, { useState } from "react";
import Icon from "./Icon";
import ReactPaginate from "react-paginate";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";

function Items({ currentItems }: { currentItems: Array<string> }) {
  return (
    <OverlayScrollbarsComponent defer>
      <ul>
        {currentItems &&
          currentItems.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    </OverlayScrollbarsComponent>
  );
}

function PaginatedItems({
  itemsPerPage,
  items,
}: {
  itemsPerPage: number;
  items: Array<string>;
}) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
      <>
        <Items currentItems={currentItems} />
        {!!(pageCount && pageCount !== 1) && (
            <div className="pagination-wrapper">
              <ReactPaginate
                  nextLabel={<Icon icon="ArrowRight" size={15} />}
                  previousLabel={<Icon icon="ArrowLeft" size={15} />}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination pagination-list"
                  activeClassName="active"
              />
            </div>
        )}
      </>
  );

}

export default PaginatedItems;
