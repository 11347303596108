import React from "react";
import { Link } from "react-router-dom";
import { price_format } from "../shared/helpers";

interface Props {
  productId?: string;
  type?: string;
  img?: string;
  name?: string;
  description?: string;
  price?: string;
  location?: string;
  id?: number;
  orange?: boolean;
  year?: number,
  km?: number
}

export default function Card({
                               productId,
                               name = "car name",
                               description = "car details",
                               price,
                               location,
                               type,
                               orange,
                               year,
                               km,
                               img = "/images/loader-gif.gif"
                             }: Props) {
  const cardType = type ? ` card-${type}` : '';
  const cardOrange = orange ? ' card-orange' : '';

    return (
      <Link className="card-link" to={`/produit?id=${productId}`}>
          <div
          >
              <div className={`card card-car${cardType}${cardOrange}`}>

                  {img && (
                      <img
                          src={img}
                          alt="car image"
                      />
                  )}
                  {img && (
                      <div className="car-infos">
                      {year ?  <span>{year}</span> : null}
                      {km ?  <span>{km} km</span> : null }
                      </div>
                  )}
                  <div className="details">
                      <div className="top">
                          <p>
                              <span className="name">{name}</span>
                              <span className="description">{description}</span>
                          </p>
                          {price && (
                              <p className="price">
                                  <span className="number">{price_format(price)}</span>
                                  <span className="coin">€</span>
                              </p>
                          )}
                      </div>
                      <div className="bottom">
                          {location && (
                              <>
                                  <p>Montant location</p>
                                  <div>
                                      <p>
                                          À partir de <br/> {price_format(location)}€/mois*
                                      </p>
                                  </div>
                              </>
                          )}
                      </div>
                  </div>
              </div>
          </div>
      </Link>
    );
}