import React from "react";
import ReactPaginate from "react-paginate";
import Icon from "./Icon";

function Pagination({
  itemsPerPage,
  items,
  onPageChange,
  pageCount,
  initialPage,
  formSubmitted
}: {
  itemsPerPage: number;
  pageCount?: any;
  items: Array<any>;
  currentItemsRender?: Array<any>;
  onPageChange?: (event) => void;
  initialPage?: any,
  formSubmitted?: any
}) {

  if(pageCount == 0 || pageCount == 1) return null;

  return (
    <div className="pagination-wrapper">
      <ReactPaginate
        nextLabel={<Icon icon="ArrowRight" size={13} />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<Icon icon="ArrowLeft" size={13} />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        initialPage={0}
      />
    </div>
  );
}
export default Pagination;
