import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import BlockProduct from "../components/blockProduct";
import { useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import axiosInstance from "../shared/axiosInstance";
import { AxiosError } from "axios";
import setAccessToken from "../shared/setAccessToken";
export default function Product() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [productData, setProductData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const fetchProducts = async () => {
        try {
          const {data} = await axiosInstance().get(`/details/${id}`);
          fetchProductsDetails(data[0]);
        }
        catch (err) {
          if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToken();
            return;
          }
          setIsLoading(false);
        }
      };

      const fetchProductsDetails = async (pData: any) => {
        try {
          const response = await axiosInstance().get(`/optionEquipment/${id}`);
          const { Equipments, Options } = response.data;
          const equipments = Equipments;
          const options = Options;
          setProductData({ ...pData, equipments, options });
        } catch (err) {
          if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToken();
            return;
          }
          console.log("Error:", (err as Error).message);
        }
        setIsLoading(false);
      };
      fetchProducts();
    }
  }, []);


  if (!id) return <h1>No Id Provided</h1>;

  if (isLoading) return <Loading />;

  return (
    <div className="page-Product">
      <PageWrapper>
        <BlockProduct productData={productData} />
      </PageWrapper>
    </div>
  );
}
