import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function legalNotice() {
  return (
      <PageWrapper>
          <Helmet>
              <title>Chasseurs d’Autos - Mentions légales de Chasseurs d’Autos</title>
              <meta
                  name="description"
                  content="Chasseurs d'Autos est une société spécialisée dans la vente de véhicules d'occasion. Nos mentions légales renseignent sur notre entreprise, nos services et notre engagement qualité et satisfaction client."
              />
              <link
                  rel="canonical"
                  href="https://chasseursdautos.com/mentions-legales"
              />
          </Helmet>
          <Head>
              <b>Mentions legales</b>
          </Head>
          <Grid container maxWidth="lg">
              <div className="legalWrapper">
                  <div>
                      <h3>EDITION DU SITE</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le site www.chasseursautos.com (ci-après, le «Site») est édité par la société CHASSEURS D’AUTOS, société par actions simplifiée, au capital de 10.000€, dont le siège social est situé 8F rue Jeanne Barret, 21000 Dijon et immatriculée au RCS de Dijon sous le numéro : 948334 141 (ci-après, l’«Éditeur»).</p>
                      <p>L’Éditeur peut être contacté à l’adresse email <a href="mailto:contact@chasseursautos.com">contact@chasseursautos.com</a></p>
                      <p>Le numéro de TVA Intracommunautaire de l’Éditeur est le: FR94443171558</p>
                  </div>
                  <div>
                      <h3>HEBERGEUR DU SITE INTERNET</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le Site est hébergé par OVH, dont le siège social est situé 2, rue Kellermann – 59100 Roubaix.</p>
                      <p>L’hébergeur est joignable au numéro suivant: 08 99 70 17 61 et à l’adresse email <a href="mailto:support@ovh.net">support@ovh.net</a>.</p>
                  </div>
                  <div>
                      <h3>RESPECT DE LA PROPRIETE INTELLECTUELLE & CREDITS</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner le Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
                      <p>Ils sont la propriété pleine et entière de l&apos;Éditeur ou de ses partenaires, sauf mentions particulières et notamment les crédits suivants:</p>
                  </div>
                  <div className="w-content">
                      <h3>Page d&apos;accueil</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Légende : Jeune Couple Choisissant Une Voiture Dans Un Salon Automobile</p>
                      <p>Crédits : senivpetro / Freepik</p>
                      <p>Légende : Stylish and elegant woman in a car salon</p>
                      <p>Crédits : prostooleh / Freepik</p>
                      <p>Légende : Close up business people hand shake</p>
                      <p>Crédits : Image par Freepik</p>
                  </div>
                  <div className="w-content">
                      <h3>Je vends mon véhicule</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Légende : Close up business people hand shake</p>
                      <p>Crédits : Image par Freepik</p>
                  </div>
                  <div className="w-content">
                      <h3>Comment ça marche ?</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Légende : Close up business people hand shake</p>
                      <p>Crédits : Image par Freepik</p>
                      <p>Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l&apos;accord préalable et écrit de l&apos;Éditeur, sont strictement interdites. Le fait pour l&apos;Éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>
                      <p>Seule l&apos;utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d&apos;atteinte aux droits voisins, sanctionnées par Code de la propriété intellectuelle. La reprise de tout ou partie de ce contenu nécessite l&apos;autorisation préalable de l&apos;Éditeur ou du titulaire des droits sur ce contenu.</p>
                  </div>
                  <div className="w-content">
                      <h3>LIENS HYPERTEXTES</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le Site peut contenir des liens hypertexte donnant accès à d&apos;autres sites web édités et gérés par des tiers et non par l&apos;Éditeur. L&apos;Éditeur ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales. </p>
                      <p>La création de liens hypertexte vers le Site ne peut être faite qu&apos;avec l&apos;autorisation écrite et préalable de l&apos;Éditeur.</p>
                  </div>
                  <div className="w-content">
                      <h3>COLLECTE DE DONNEES PERSONNELLES</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l&apos;informatique, aux fichiers et aux libertés, l’utilisateur est informé que l&apos;Éditeur procède à la collecte et au traitement de données personnelles, lors de la connexion au Site. </p>
                      <p>Les données collectées servent principalement à permettre la mise à disposition de la plateforme, son optimisation et sa sécurisation afin d’offrir aux utilisateurs un service de la meilleure qualité possible. </p>
                      <p>Des données supplémentaires pourront éventuellement être collectées par l’Éditeur pour permettre la bonne exécution de contrats commerciaux conclus par l’entremise de la plateforme avec l’utilisateur.
                          L’utilisateur est informé qu’il dispose d&apos;un droit d&apos;accès, d&apos;interrogation, de modification et de suppression des informations qui le concerne, à exercer à tout moment auprès de l&apos;Éditeur soit directement sur le Site, soit par courrier postal adressé au 8F rue Jeanne Barret, 21000 Dijon, soit par e-mail à l’adresse <a href="mailto:contact@chasseursautos.com">contact@chasseursautos.com</a>.</p>
                      <p>Les informations recueillies pourront éventuellement être partagées à des tiers résidant dans l’Union Européenne dans les cas suivants:</p>
                      <p className="p-left">Lorsque l’utilisateur publie, dans une zone de commentaire, des informations accessibles au public</p>
                      <p className="p-left">Lorsque l’utilisateur y consent expressément</p>
                      <p className="p-left">Lorsque la loi l’exige ou afin de coopérer à une enquête judiciaire à la demande exprès d’un détenteur de l’autorité publique</p>
                      <p className="p-left">Pour l’exécution de prestations commerciales pour lesquelles est rendu nécessaire la coopération d’un tiers, notamment dans le cas où l’Éditeur choisirait de sous-traiter certaines de ses prestations par l’entremise de services tiers.</p>
                      <p>Lorsque certaines informations sont obligatoires pour accéder à des fonctionnalités spécifiques du Site, l&apos;Éditeur indiquera ce caractère obligatoire au moment de la saisie des données.</p>
                      <p>Les données personnelles collectées ne seront conservées que le temps nécessaire pour permettre la bonne utilisation du Site, empêcher les fraudes et abus, et satisfaire aux obligations légales et réglementaires de l’Éditeur concernant la gestion du Site.</p>
                  </div>
              </div>
          </Grid>
      </PageWrapper>
  );
}
