let initialState = null;


export default function userReducer(state = initialState, action) {
  if (action.type === 'SET_USER') {
    return action.payload;
  } else {
    return state;
  }
}
