import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function TermsConditions() {
    return (
        <PageWrapper>
            <Helmet>
                <title>Chasseurs d’Autos - Conditions générales d’utilisation de Chasseurs d’Autos</title>
                <meta
                    name="description"
                    content="En utilisant notre site web, vous acceptez nos conditions générales d'utilisation"
                />
                <link
                    rel="canonical"
                    href="https://chasseursdautos.com/conditions-generales-utilisation"
                />
            </Helmet>
            <Head>
                <b>Conditions générales D&apos;UTILISATION</b>
            </Head>
            <Grid container maxWidth="lg">
                <div className="legalWrapper">
                    <div>
                        <h3>1 - OBJET</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les présentes conditions générales d’utilisation ont pour objet de définir les modalités et conditions d’utilisation des services proposés sur le site (ci-après: les «Services»), ainsi que de définir les droits et obligations des parties dans ce cadre.</p>
                        <p>Elles sont notamment accessibles et imprimables à tout moment par un lien direct en bas de la page d’accueil du site.</p>
                        <p>Elles peuvent être complétées, le cas échéant, par des conditions d’utilisation particulières à certains Services. En cas de contradiction, les conditions particulières prévalent sur ces conditions générales.</p>
                        <p>L&apos;inscription de vos coordonnées sur ce site, notamment sur nos formulaires de contact, ne constitue en aucun cas un contrat de location avec option d&apos;achat.</p>
                        <p>Les services mentionnés sur le site ainsi que les éventuelles simulations n’ont pas de valeur contractuelle et ne constituent pas une offre contractuelle. Ces informations sont susceptibles d’être modifiées à tout moment. L’inscription de vos coordonnées sur ce site ne constitue en aucun cas un engagement contractuel.</p>
                        <p>Une offre préalable de financement pourra vous être adressée sur demande, après entretien avec notre service commercial, et, en cas d’acceptation de votre part, constituera un contrat de LOA sous réserve d’acceptation</p>
                    </div>
                    <div>
                        <h3>2 – EXPLOITANT DES SERVICES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les Services sont exploités par la société CHASSEURS D’AUTOS, société par actions simplifiée, au capital de 10.000€, dont le siège social est situé 8F rue Jeanne Barret, 21000 Dijon, et immatriculée au RCS de Dijon sous le numéro : 948334 131 (ci-après: «<strong>Chasseurs d’Autos</strong>»).</p>
                        <p>Chasseurs d’Autos peut être contactée aux coordonnées suivantes:</p>
                        <p>Adresse postale: 8F rue Jeanne Barret, 21000 Dijon</p>
                        <p>Adresse électronique: <a href="mailto:contact@chasseursautos.com">contact@chasseursautos.com</a></p>
                    </div>
                    <div>
                        <h3>3 – ACCES AU SITE ET AUX SERVICES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les Services sont accessibles, sous réserve des restrictions prévues sur le site:</p>
                        <p className="p-left">- à toute personne physique disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder au Site et aux Services qu’avec l’accord de son représentant légal;</p>
                        <p className="p-left">à toute personne morale agissant par l’intermédiaire d’une personne physique disposant de la capacité juridique pour contracter au nom et pour le compte de la personne morale.</p>
                    </div>
                    <div>
                        <h3>4 – ACCEPTATION DES CONDITIONS GENERALES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>L’acceptation des présentes conditions générales est matérialisée par une case à cocher dans le formulaire d’inscription et/ou de contact. Cette acceptation ne peut être que pleine et entière. Toute adhésion sous réserve est considérée comme nulle et non avenue. L’Utilisateur qui n’accepte pas d’être lié par les présentes conditions générales ne doit pas utiliser les Services.</p>
                    </div>
                    <div>
                        <h3>5 – INSCRIPTION SUR LE SITE</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>L’utilisation des Services ne nécessite pas que l’Utilisateur s’inscrive sur le site.</p>
                    </div>
                    <div>
                        <h3>6 – DESCRIPTION DES SERVICES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>L’Utilisateur a accès aux Services décrits sur le site, sous une forme et selon les fonctionnalités et moyens techniques que Chasseurs d’Autos juge les plus appropriés.</p>
                    </div>
                    <div>
                        <h3>7 – SERVICES PAYANTS</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Le prix des Services est indiqué sur le site.</p>
                        <p>Sauf mention contraire, ils sont exprimés en euros et toutes taxes françaises comprises.</p>
                        <p>Chasseurs d’Autos se réserve le droit, à sa libre discrétion et selon des modalités dont elle sera seule juge, de proposer des offres promotionnelles ou réductions de prix.</p>
                        <p>Le prix de Services peut faire l’objet d’une révision par Chasseurs d’Autos à tout moment, à sa libre discrétion.</p>
                        <p>L’Utilisateur sera informé de ces modifications par Chasseurs d’Autos par email sous un préavis de trois (3) mois au moins avant l’entrée en vigueur des nouveaux tarifs.</p>
                        <p>L’Utilisateur qui n’accepte pas les nouveaux prix doit mettre fin à son utilisation des Services selon les modalités prévues à l’article 18. A défaut, il sera réputé avoir accepté les nouveaux tarifs.</p>
                        <p>Les Services font l’objet de factures qui sont communiquées à l’Utilisateur par tout moyen utile.</p>
                        <p>Les modalités de paiement du prix des Services sont décrites sur le site.</p>
                    </div>
                    <div>
                        <h3>8 – DONNEES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>L’Utilisateur reconnaît et accepte expressément:</p>
                        <p className="p-left">(I) que les données recueillies sur le site et sur les équipements informatiques de Chasseurs d’Autos font foi de la réalité des opérations intervenues dans le cadre des présentes;</p>
                        <p className="p-left">(II) que ces données constituent le seul mode de preuve admis entre les parties, notamment pour le calcul des sommes dues à Chasseurs d’Autos.</p>
                        <p>L’Utilisateur peut accéder à ces données dans son Espace Personnel.</p>
                    </div>
                    <div>
                        <h3>9 – OBLIGATIONS DE L’UTILISATEUR</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Sans préjudice des autres obligations prévues aux présentes, l’Utilisateur s’engage à respecter les obligations qui suivent:</p>
                        <p>L’Utilisateur s’engage, dans son usage des Services, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux droits de tiers ou à l’ordre public.</p>
                        <p>Il est notamment seul responsable du bon accomplissement de toutes les formalités notamment administratives, fiscales et/ ou sociales et de tous les paiements de cotisations, taxes ou impôts de toutes natures qui lui incombent, le cas échéant, en relation avec son utilisation des Services. La responsabilité de Chasseurs d’Autos ne pourra en aucun cas être engagée à ce titre.</p>
                        <p>L’Utilisateur reconnaît avoir pris connaissance sur le site des caractéristiques et contraintes, notamment techniques, de l’ensemble des Services. Il est seul responsable de son utilisation des Services.</p>
                        <p>L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.</p>
                        <p>L’Utilisateur est également seul responsable des relations qu’il pourra nouer avec les autres Utilisateurs et des informations qu’il leur communique dans le cadre des Services. Il lui appartient d’exercer la prudence et le discernement appropriés dans ces relations et communications. L’Utilisateur s’engage en outre, dans ses échanges avec les autres Utilisateurs, à respecter les règles usuelles de politesse et de courtoisie.</p>
                        <p>L’Utilisateur s’engage à faire un usage strictement personnel des Services. Il s’interdit en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des présentes à un tiers, de quelque manière que ce soit.</p>
                        <p>L’Utilisateur s’engage à fournir à Chasseurs d’Autos toutes les informations nécessaires à la bonne exécution des Services. Plus généralement, l’Utilisateur s’engage à coopérer activement avec Chasseurs d’Autos en vue de la bonne exécution des présentes.</p>
                        <p>L’Utilisateur est seul responsable des contenus de toute nature (rédactionnels, graphiques, audiovisuels ou autres, en ce compris la dénomination et/ou l’image éventuellement choisies par l’Utilisateur pour l’identifier sur le site) qu’il diffuse dans le cadre des Services (ci-après désignés: les «<strong>Contenus</strong>»).</p>
                        <p>Il garantit à Chasseurs d’Autos qu’il dispose de tous les droits et autorisations nécessaires à la diffusion de ces Contenus.</p>
                        <p>Il s’engage à ce que lesdits Contenus soient licites, ne portent pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, n’enfreignent aucune disposition législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la responsabilité civile ou pénale de Chasseurs d’Autos.</p>
                        <p>L’Utilisateur s’interdit ainsi de diffuser, notamment et sans que cette liste soit exhaustive:</p>
                        <p className="p-left">- des Contenus pornographiques, obscènes, indécents, choquants ou inadaptés à un public familial, diffamatoires, injurieux, violents, racistes, xénophobes ou révisionnistes,</p>
                        <p className="p-left">- des Contenus contrefaisants</p>
                        <p className="p-left">- des Contenus attentatoires à l’image d’un tiers</p>
                        <p className="p-left">- des Contenus mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou trompeuses</p>
                        <p className="p-left">- des Contenus nuisibles aux systèmes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc.)</p>
                        <p className="p-left">- et plus généralement des Contenus susceptibles de porter atteinte aux droits de tiers ou d’être préjudiciables à des tiers, de quelque manière et sous quelque forme que ce soit.</p>
                        <p>L’Utilisateur reconnaît que les Services lui offrent une solution supplémentaire mais non alternative des moyens qu’il utilise déjà par ailleurs pour atteindre le même objectif et que cette solution ne saurait se substituer à ces autres moyens.</p>
                        <p>L’Utilisateur doit prendre les mesures nécessaires pour sauvegarder par ses propres moyens les informations qu’il juge nécessaires, dont aucune copie ne lui sera fournie.</p>
                        <p>L’Utilisateur est informé et accepte que la mise en œuvre des Services nécessite qu’il soit connecté à internet et que la qualité des Services dépend directement de cette connexion, dont il est seul responsable.</p>
                    </div>
                    <div>
                        <h3>10 – GARANTIE DE L’UTILISATEUR</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>L’Utilisateur garantit Chasseurs d’Autos contre toutes plaintes, réclamations, actions et/ou revendications quelconques que Chasseurs d’Autos pourrait subir du fait de la violation, par l’Utilisateur de l’une quelconque de ses obligations ou garanties aux termes des présentes conditions générales.</p>
                        <p>Il s’engage à indemniser Chasseurs d’Autos de tout préjudice qu’elle subirait et à lui payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.</p>
                    </div>
                    <div>
                        <h3>11 – COMPORTEMENTS PROHIBES</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Il est strictement interdit d’utiliser les Services aux fins suivantes:</p>
                        <p className="p-left">- l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,</p>
                        <p className="p-left">- l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</p>
                        <p className="p-left">- l’intrusion dans le système informatique d’un tiers ou toute activité de nature à nuire, contrôler, interférer, ou intercepter tout ou partie du système informatique d’un tiers, en violer l’intégrité ou la sécurité,</p>
                        <p className="p-left">- l’envoi d’emails non sollicités et/ou de prospection ou sollicitation commerciale,</p>
                        <p className="p-left">- les manipulations destinées à améliorer le référencement d’un site tiers,</p>
                        <p className="p-left">- l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,</p>
                        <p className="p-left">- et plus généralement toute pratique détournant les Services à des fins autres que celles pour lesquelles ils ont été conçus.</p>
                        <p>Il est strictement interdit aux Utilisateurs de copier et/ou de détourner à leurs fins ou à celles de tiers le concept, les technologies ou tout autre élément du site de Chasseurs d’Autos.</p>
                        <p>Sont également strictement interdits: (i) tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Services, (ii) toutes intrusions ou tentatives d’intrusions dans les systèmes de Chasseurs d’Autos, (iii) tous détournements des ressources système du site, (iv) toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de sécurité et d’authentification, (vi) tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de Chasseurs d’Autos ou des usagers de son site, et enfin plus généralement (vii) tout manquement aux présentes conditions générales.</p>
                        <p>Il est strictement interdit de monnayer, vendre ou concéder tout ou partie de l’accès aux Services ou au site, ainsi qu’aux informations qui y sont hébergées et/ou partagées.</p>
                    </div>
                    <div>
                        <h3>12 – SANCTIONS DES MANQUEMENTS</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>En cas de manquement à l’une quelconque des dispositions des présentes conditions générales ou plus généralement, d’infraction aux lois et règlements en vigueur par un Utilisateur, Chasseurs d’Autos se réserve le droit de prendre toute mesure appropriée et notamment de:</p>
                        <p className="p-left">(I) suspendre ou résilier l’accès aux Services de l’Utilisateur, auteur du manquement ou de l’infraction, ou y ayant participé,</p>
                        <p className="p-left">(II) supprimer tout contenu mis en ligne sur le site,</p>
                        <p className="p-left">(III) publier sur le site tout message d’information que Chasseurs d’Autos jugera utile,</p>
                        <p className="p-left">(IV) avertir toute autorité concernée,</p>
                        <p className="p-left">(V) engager toute action judiciaire.</p>
                    </div>
                    <div>
                        <h3>13 – RESPONSABILITE ET GARANTIE DE Chasseurs d’Autos</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Chasseurs d’Autos s’engage à fournir les Services avec diligence et selon les règles de l’art, étant précisé qu’il pèse sur elle une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément.</p>
                        <p>Chasseurs d’Autos n’a pas connaissance des Contenus mis en ligne par les Utilisateurs dans le cadre des Services, sur lesquels elle n’effectue aucune modération, sélection, vérification ou contrôle d’aucune sorte et à l’égard desquels elle n’intervient qu’en tant que prestataire d’hébergement.</p>
                        <p>En conséquence, Chasseurs d’Autos ne peut être tenue pour responsable des Contenus, dont les auteurs sont des tiers, toute réclamation éventuelle devant être dirigée en premier lieu vers l’auteur des Contenus en question.</p>
                        <p>Les Contenus préjudiciables à un tiers peuvent faire l’objet d’une notification à Chasseurs d’Autos selon les modalités prévues par l’article 6 I 5 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l&apos;économie numérique, Chasseurs d’Autos se réservant de prendre les mesures décrites à l’article 12.</p>
                        <p>Chasseurs d’Autos décline toute responsabilité en cas de perte éventuelle des informations de l’Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant prétendre à aucun dédommagement à ce titre.</p>
                        <p>Chasseurs d’Autos s’engage à procéder régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité du site. A ce titre, Chasseurs d’Autos se réserve la faculté d’interrompre momentanément l’accès au site pour des raisons de maintenance. De même,  Chasseurs d’Autos ne saurait être tenue responsable des difficultés ou impossibilités momentanées d’accès au site qui auraient pour origine des circonstances qui lui sont extérieures, la force majeure, ou encore qui seraient dues à des perturbations des réseaux de télécommunication.</p>
                        <p>Chasseurs d’Autos ne garantit pas aux Utilisateurs (i) que les Services, soumis à une recherche constante pour en améliorer notamment la performance et le progrès, seront totalement exempts d’erreurs, de vices ou défauts, (ii) que les Services, étant standard et nullement proposés à la seule intention d’un Utilisateur donné en fonction de ses propres contraintes personnelles, répondront spécifiquement à ses besoins et attentes.</p>
                        <p>En tout état de cause, la responsabilité susceptible d’être encourue par Chasseurs d’Autos au titre des présentes est expressément limitée aux seuls dommages directs avérés subis par l’Utilisateur.</p>
                    </div>
                    <div>
                        <h3>14 – PROPRIETE INTELLECTUELLE</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les systèmes, logiciels, structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de données, etc …) exploités par Chasseurs d’Autos au sein du site sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de données en vigueur. Tous désassemblages, décompilations, décryptages, extractions, réutilisations, copies et plus généralement, tous actes de reproduction, représentation, diffusion et utilisation de l’un quelconque de ces éléments, en tout ou partie, sans l’autorisation de Chasseurs d’Autos sont strictement interdits et pourront faire l’objet de poursuites judiciaires.</p>
                    </div>
                    <div>
                        <h3>15 – DONNEES A CARACTÈRE PERSONNEL</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Chasseurs d’Autos pratique une politique de protection des données personnelles dont les caractéristiques sont explicitées dans le document intitulé «Charte de confidentialité», dont l’Utilisateur est expressément invité à prendre connaissance sur le site.</p>
                    </div>
                    <div>
                        <h3>16 – PUBLICITE</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Chasseurs d’Autos se réserve la faculté d’insérer sur toute page du site et dans toute communication aux Utilisateurs tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont Chasseurs d’Autos sera seule juge.</p>
                    </div>
                    <div>
                        <h3>17 – LIENS ET SITES TIERS</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Chasseurs d’Autos ne pourra en aucun cas être tenue pour responsable de la disponibilité technique de sites internet ou d’applications mobiles exploités par des tiers (y compris ses éventuels partenaires) auxquels l’Utilisateur accéderait par l&apos;intermédiaire du site.</p>
                        <p>Chasseurs d’Autos n&apos;endosse aucune responsabilité au titre des contenus, publicités, produits et/ou services disponibles sur de tels sites et applications mobiles tiers dont il est rappelé qu’ils sont régis par leurs propres conditions d’utilisation.</p>
                        <p>Chasseurs d’Autos n&apos;est pas non plus responsable des transactions intervenues entre l’Utilisateur et un quelconque annonceur, professionnel ou commerçant (y compris ses éventuels partenaires) vers lequel l’Utilisateur serait orienté par l&apos;intermédiaire du site et ne saurait en aucun cas être partie à quelques litiges éventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou services, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont tenus.</p>
                    </div>
                    <div>
                        <h3>18 – DUREE DES SERVICES, DESINSCRIPTION</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les Services sont souscrits pour une durée indéterminée.</p>
                        <p>L’Utilisateur peut se désinscrire des Services à tout moment, en adressant une demande à cet effet à Chasseurs d’Autos par email, aux coordonnées mentionnées à l’article 2.</p>
                        <p>La désinscription est effective immédiatement. Elle entraîne la suppression automatique du Compte de l’Utilisateur.</p>
                    </div>
                    <div>
                        <h3>19 – MODIFICATIONS</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Chasseurs d’Autos se réserve la faculté de modifier à tout moment les présentes conditions générales.</p>
                        <p>L’Utilisateur sera informé de ces modifications par tout moyen utile.</p>
                        <p>L’Utilisateur qui n’accepte pas les conditions générales modifiées doit se désinscrire des Services selon les modalités prévues à l’article 18.</p>
                        <p>Tout Utilisateur qui a recours aux Services postérieurement à l’entrée en vigueur des conditions générales modifiées est réputé avoir accepté ces modifications.</p>
                    </div>
                    <div>
                        <h3>20 – LANGUE</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Dans l’hypothèse d’une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d’interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d’un terme ou d’une disposition.</p>
                    </div>
                    <div>
                        <h3>21– LOI APPLICABLE ET JURIDICTION</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les présentes conditions générales sont régies par la loi française.</p>
                        <p>En cas de contestation sur la validité, l’interprétation et/ou l’exécution des présentes conditions générales, les parties conviennent que les tribunaux de Paris seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.</p>
                    </div>
                    <div>
                        <h3>22 – ENTREE EN VIGUEUR</h3>
                        <hr/>
                    </div>
                    <div>
                        <p>Les présentes conditions générales sont entrées en vigueur le 22 juin 2023.</p>
                    </div>
                </div>
            </Grid>
        </PageWrapper>
    );
}
