import React from "react";
import Grid from "./grid";

interface Props {
  children: any;
  top?: boolean;
  guid?: boolean;
}

export default function blockImage({ children, top, guid }: Props) {
  const getImageSource = () => {
    let imageName = "1.png";
    if (top) {
      imageName = "2.png";
    } else if (guid) {
      imageName = "3.png";
    }
    return `/images/buy-${imageName}`;
  };
  const imageSource = getImageSource();
  return (
    <div
      className={`block-image${top ? " block-image-top" : ""}${
        guid ? " block-guid" : ""
      }`}
    >
      <Grid
        container
        maxWidth="lg"
        columnSpacing={{
          xs: 2,
        }}
        rowSpacing={{
          xs: 8,
        }}
        sx={{
          px: 2,
        }}
        className="block-image-wrapper"
      >
        <Grid
          xs={12}
          md={7}
          sx={{
            my: "auto",
          }}
        >
          <div className="title">{children[0]}</div>
          {children[1]}
          {children[2]}
        </Grid>

        <Grid
          xs={12}
          md={5}
          sx={{
            mb: 0,
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <img src={imageSource} alt="buy" />
        </Grid>
      </Grid>
    </div>
  );
}
