import React from "react";
import Grid from "./grid";
import { NavLink, Link } from "react-router-dom";
import Icon from "../components/Icon";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-bottom">
        <Grid container className="container" maxWidth="lg" spacing={2}>
          <Grid xs={12} md={9} className="nav-list">
            <ul>
              <li>
                <NavLink to="/">Accueil</NavLink>
              </li>
              <li>
                <NavLink to="/acheter">J’achète mon véhicule</NavLink>
              </li>
              <li>
                <NavLink to="/vendre">Je vends mon véhicule</NavLink>
              </li>
              <li>
                <NavLink to="/qui-sommes-nous">Qui sommes nous&nbsp;?</NavLink>
              </li>
              <li>
                <NavLink to="/comment-ca-marche">Comment ça marche&nbsp;?</NavLink>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={3} className="nav-social">
            <ul>
              <li>
                <a rel="noreferrer" href="https://fr.linkedin.com/company/chasseurs-d-autos" target="_blank">
                  <Icon icon="linkedin" size="24" />
                </a>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} className="footer-privacy">
            <Grid
              container
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: "row",
                },
              }}
            >
              <Grid xs={12} md={4}>
                <p className="cr">
                  &copy; {currentYear} Chasseurs d’Autos - All rights reserved.
                </p>
              </Grid>
              <Grid xs={12} md={4} className="footer-logo-dk">
                <Link to="/">
                  <img src="./images/logo.png" alt="Logo" />
                </Link>
              </Grid>
              <Grid xs={12} md={4}>
                <ul>
                  <li>
                    <NavLink to="/mentions-legales">Mentions Légales</NavLink>
                  </li>
                  <li>
                    <NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink>
                  </li>
                  <li>
                    <NavLink to="/conditions-generales-vente">Conditions Générales de Vente</NavLink>
                  </li>
                  <li>
                    <NavLink to="/politique-de-cookies">Politique des cookies</NavLink>
                  </li>
                  <li>
                    <NavLink to="/conditions-generales-utilisation">Conditions générales d&apos;utilisation</NavLink>
                  </li>

                </ul>
              </Grid>
              <Grid xs={12} md={4} className="footer-logo-mb">
                <Link to="/">
                  <img src="./images/logo.png" alt="Logo" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};
export default Footer;
