import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
      <PageWrapper>
          <Helmet>
              <title>Chasseurs d’Autos - Politique de confidentialité de Chasseurs d’Autos</title>
              <meta
                  name="description"
                  content="Chasseurs d'Autos respecte votre vie privée et s'engage à protéger vos informations personnelles. Notre politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données"
              />
              <link
                  rel="canonical"
                  href="https://chasseursdautos.com/politique-de-confidentialite"
              />
          </Helmet>
          <Head>
              <b>Politique de confidentialité</b>
          </Head>
          <Grid container maxWidth="lg">
              <div className="legalWrapper">
                  <div>
                      <p>La présente politique de confidentialité a pour vocation de vous aider à comprendre comment sont collectées, traitées et conservées vos données personnelles par Chasseurs d&apos;Autos via le Site.</p>
                      <p>Chasseurs d&apos;Autos comprend que la protection des données et de la vie privée soit un enjeu pour l&apos;ensemble des utilisateurs visitant le Site.</p>
                      <p>Chasseurs d&apos;Autos s&apos;engage, conformément à la règlementation RGPD, à respecter votre vie privée et à protéger vos données à caractère personnel, c&apos;est à dire susceptible de vous identifier directement ou indirectement en tant que personne.</p>
                      <p>L’objectif de la présente politique de confidentialité est de vous présenter les engagements de  Chasseurs d’Autos en la matière.</p>
                  </div>
                  <div>
                      <h3>DEFINITION</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Lors de votre utilisation de notre Site, nous pouvons être amenés à vous demander de nous communiquer des données à caractère personnel vous concernant.</p>
                      <p>Le terme « données à caractère personnel » désigne toutes les données qui permettent d’identiﬁer un individu, ce qui correspond notamment à vos nom, prénoms, pseudonyme, photographie, adresse postale et de courrier électronique, intitulé de votre poste, numéros de téléphone, date de naissance, données relatives à vos transactions sur le Site, détails de vos achats, numéros de carte bancaire, SIRET, numéro de TVA intracommunautaire, adresse IP, ainsi qu’à tout autre renseignement que vous choisirez de nous communiquer à votre sujet.</p>
                  </div>
                  <div>
                      <h3>OBJET</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>La présente charte (ci-après la « <strong>Charte</strong> ») a pour objet de vous informer sur les moyens que nous mettons en œuvre pour collecter vos données à caractère personnel, dans le respect le plus strict de vos droits.</p>
                      <p>Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion de vos données à caractère personnel, à la loi n° 78-17 du 6 janvier 1978 relative à l&apos;informatique, aux ﬁchiers et aux libertés, dans sa version actuelle (ci-après : la « Loi Informatique et Libertés »), ainsi que le règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l&aposégard du traitement des données à caractère personnel et à la libre circulation de ces données (ci- après : le «<strong>RGPD</strong>»).</p>
                  </div>
                  <div>
                      <h3>IDENTITE DU RESPONSABLE DE LA COLLECTE DES DONNEES</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le responsable de la collecte de vos données à caractère personnel est la société CHASSEURS D’AUTOS, société par actions simplifiée, au capital de 10.000€, dont le siège social est situé 8F rue Jeanne Barret, 21000 Dijon, et immatriculée au RCS de Dijon sous le numéro : 948334 131 (dénommée ci-après : « <strong>Nous</strong> »).</p>
                  </div>
                  <div>
                      <h3>COLLECTE DES DONNEES A CARACTERE PERSONNEL</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le fondement légal de notre collecte de vos données personnelles est le suivant :</p>
                      <p className="p-left">- cette collecte est nécessaire aﬁn d’exécuter le contrat conclu lorsque vous utilisez nos services sur notre Site;</p>
                      <p className="p-left">- l’intérêt légitime lorsque vous nous fournissez volontairement des données à caractère personnel lors de votre visite sur notre Site, les données étant alors collectées pour nous permettre de mieux répondre à vos demandes d’information sur nos services.</p>
                      <p>Vos données à caractère personnel sont collectées pour répondre à une ou plusieurs des ﬁnalités suivantes :</p>
                      <p className="p-left">- Gérer votre accès à certains services accessibles sur le Site et leur utilisation,</p>
                      <p className="p-left">- Eﬀectuer les opérations relatives à la gestion des Clients concernant les contrats, factures, suivi de la relation avec les Clients,</p>
                      <p className="p-left">- Constituer un ﬁchier d’utilisateurs, de clients et prospects,</p>
                      <p className="p-left">- Adresser des newsletters, sollicitations et messages promotionnels. Dans le cas où vous ne le souhaiteriez pas, nous vous donnons la faculté d’exprimer votre refus à ce sujet lors de la collecte de vos données,</p>
                      <p className="p-left">- Élaborer des statistiques commerciales et de fréquentation de nos services,</p>
                      <p className="p-left">- Organiser des jeux concours, loteries et toutes opérations promotionnelles à l’exclusion des jeux d’argent et de hasard en ligne soumis à l’agrément de l’Autorité de Régulation des Jeux en ligne</p>
                      <p className="p-left">- Gérer la gestion des avis des personnes sur des produits, services ou contenus,</p>
                      <p className="p-left">- Gérer les impayés et les contentieux éventuels quant à l’utilisation de nos produits et services,</p>
                      <p className="p-left">- Personnaliser les réponses à vos demandes d’information,</p>
                      <p className="p-left">- Respecter nos obligations légales et réglementaires. Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives (par des astérisques visibles sur le Site). </p>
                  </div>
                  <div>
                      <table>
                          <tr>
                              <td>Pourquoi collectons-nous vos données personnelles?</td>
                              <td>Quelles sont les catégories de données personnelles que nous collectons à cette fin?</td>
                              <td>Quelle est la base juridique nous autorisant à collecter vos données personnelles pour une telle finalité?</td>
                          </tr>
                          <tr>
                              <td><p>Nous collectons vos données personnelles afin de vous identifier lors de l’utilisation de notre site et afin de vous adresser des messages liés à la bonne administration de notre site (ex: confirmation d’inscription, modification du site ou des conditions d’utilisation etc…)</p></td>
                              <td>
                                 <div className="imageWrapper">
                                     <div>
                                         <img src="/images/card.png" alt="image"/>
                                         <p><strong>Données d’identification</strong></p>
                                     </div>
                                     <div>
                                        <img src="/images/ip.png" alt="image"/>
                                         <p><strong>Données de connexion</strong></p>
                                     </div>
                                 </div>
                              </td>
                              <td><p>Ce traitement a pour base juridique l’exécution du contrat nous liant lors de votre acceptation de nos conditions générales au moment de la création de son compte</p></td>
                          </tr>
                          <tr>
                              <td><p>Nous collectons vos données personnelles afin de vous livrer votre commande/service.</p></td>
                              <td>
                                     <center>
                                         <div>
                                             <img src="/images/card.png" alt="image"/>
                                             <p><strong>Données d’identification</strong></p>
                                         </div>
                                     </center>
                              </td>
                              <td><p>Ce traitement a pour base juridique l’exécution du contrat nous liant lors de votre acceptation de nos conditions générales au moment de la création de son compte</p></td>
                          </tr>
                          <tr>
                              <td><p>Nous collectons vos données personnelles afin de comprendre quelles sont vos préférences et vous proposer des offres commerciales correspondant à des services/produits analogues à ceux déjà commandés.</p></td>
                              <td>
                                  <div className="imageWrapper">
                                      <div>
                                          <img src="/images/money.png" alt="image"/>
                                          <p><strong>Données d’utilisation et consommation</strong></p>
                                      </div>
                                      <div>
                                          <img src="/images/ip.png" alt="image"/>
                                          <p><strong>Données de connexion</strong></p>
                                      </div>
                                  </div>
                              </td>
                              <td><p>Ce traitement a pour base juridique notre intérêt légitime à fournir et améliorer l’expérience utilisateur de nos visiteurs et membres de notre site.</p></td>
                          </tr>
                          <tr>
                              <td><p>Nous collectons vos données personnelles afin d’améliorer et optimiser notre site, par exemple en étudiant votre comportement lors de votre visite et d’en tirer des conséquences afin d’apporter des modifications en termes d’ergonomie et expérience utilisateur.</p></td>
                              <td>
                                  <div className="imageWrapper">
                                      <div>
                                          <img src="/images/money.png" alt="image"/>
                                          <p><strong>Données d’utilisation et consommation</strong></p>
                                      </div>
                                      <div>
                                          <img src="/images/card.png" alt="image"/>
                                          <p><strong>Données de connexion</strong></p>
                                      </div>
                                  </div>
                              </td>
                              <td><p>Ce traitement a pour base juridique notre intérêt légitime à fournir et améliorer l’expérience utilisateur de nos visiteurs et membres de notre site.</p></td>
                          </tr>
                      </table>
                      <p>Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives.</p>
                  </div>
                  <div>
                      <h3>DESTINATAIRE DES DONNEES A CARACTERE PERSONNEL</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Auront accès à vos données personnelles :</p>
                      <p className="p-left">- Le personnel de notre société ;</p>
                      <p className="p-left">- Les services chargés du contrôle (avocat, comptable notamment) ;</p>
                      <p className="p-left">- Nos sous-traitants (prestataires de communication, prestataires de logiciels de productivité, prestataire d’hébergement des données, prestataires de service de paiement, prestataire de facturation, prestataires d’analyse et de mesure d’audience, prestataires de gestion de la relation client) ;</p>
                      <p className="p-left">- Peuvent également être destinataires de vos données à caractère personnel les organismes publics, exclusivement pour répondre à nos obligations légales, les auxiliaires de justice, les oﬃciers ministériels et les organismes chargés d’eﬀectuer le recouvrement de créances.</p>
                  </div>
                  <div>
                      <h3>DUREE DE CONSERVATION DES DONNEES A CARACTERE PERSONNEL</h3>
                      <hr/>
                  </div>
                  <div>
                      <p><span>Concernant les données relatives à la gestion de clients et prospects :</span></p>
                      <p>Vos données à caractère personnel ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de notre relation commerciale avec vous.</p>
                      <p>Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être conservées au titre du respect d’une obligation légale, le seront pendant la durée prévue par la loi en vigueur.</p>
                      <p>Concernant d’éventuelles opérations de prospection à destination des clients, leurs données pourront être conservées pendant un délai de trois (3) ans à compter de la ﬁn de la relation commerciale.</p>
                      <p>Les données à caractère personnel relatives à un prospect, non client, pourront être conservées pendant un délai de trois (3) ans à compter de leur collecte ou du dernier contact émanant du prospect.</p>
                      <p>Au terme de ce délai de trois (3) ans, nous pourrons reprendre contact avec vous pour savoir si vous souhaitez continuer à recevoir des sollicitations commerciales.</p>
                      <p>En cas d’exercice du droit d’accès ou de rectiﬁcation, les données relatives aux pièces d’identité pourront être conservées pendant le délai prévu à l’article 9 du code de procédure pénale, soit un (1) an. En cas d’exercice du droit d’opposition, ces données peuvent être archivées pendant le délai de prescription prévu par l’article 8 du code de procédure pénale, soit trois (3) ans.</p>
                      <p>Les transactions ﬁnancières relatives au paiement des achats et des frais via le Site, sont conﬁées à un prestataire de services de paiement qui en assure le bon déroulement et la sécurité.</p>
                      <p>Pour les besoins des services, ce prestataire de services de paiement peut être amené à être destinataire de vos données à caractère personnel relatives à vos numéros de cartes bancaires, qu’il recueille et conserve en notre nom et pour notre compte. Nous n’avons pas accès à ces données.</p>
                      <p>Pour vous permettre de réaliser régulièrement des achats ou de régler les frais aﬀérents sur le Site, vos données relatives à vos cartes bancaires sont conservées pendant le temps de votre inscription sur le Site et à tout le moins, jusqu’au moment où vous réalisez votre dernière transaction.</p>
                      <p>Les données relatives au cryptogramme visuel ou CVV2, inscrit sur votre carte bancaire, ne sont pas stockées.</p>
                      <p>Si vous refusez que vos données à caractère personnel relatives à vos numéros de cartes bancaires soient conservées dans les conditions précisées ci-dessus, nous ne conserverons pas ces données au-delà du temps nécessaire pour permettre la réalisation de la transaction.</p>
                      <p>En tout état de cause, les données relatives à celles-ci pourront être conservées, pour une ﬁnalité de preuve en cas d’éventuelle contestation de la transaction, en archives intermédiaires, pour la durée prévue par l’article L 133-24 du Code monétaire et ﬁnancier, en l’occurrence treize (13) mois suivant la date de débit. Ce délai peut être étendu à quinze (15) mois aﬁn de prendre en compte la possibilité d’utilisation des cartes de paiement à débit diﬀéré.</p>
                      <p><span>Concernant la gestion des listes d’opposition à recevoir de la prospection :</span></p>
                      <p>Les informations permettant de prendre en compte votre droit d’opposition sont conservées au minimum trois (3) ans à compter de l’exercice du droit d’opposition.</p>
                      <p><span>Concernant les statistiques de mesure d’audience :</span></p>
                      <p>Les informations stockées dans le terminal des utilisateurs ou tout autre élément utilisé pour identiﬁer les utilisateurs et permettant leur traçabilité ou fréquentation ne seront pas conservées au-delà de treize (13) mois.</p>
                  </div>
                  <div>
                      <h3>SECURITE</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la conﬁdentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès. Nous recourrons également à des systèmes de paiement sécurisé conformes à l’état de l’art et à la réglementation applicable.</p>
                  </div>
                  <div>
                      <h3>HEBERGEMENT</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Nous vous informons que vos données sont conservées et stockées, pendant toute la durée de leur conservation sur les serveurs de la société OVH situés dans l’Union européenne.</p>
                  </div>
                  <div>
                      <h3>TRANSFERT HORS DE L’UNION EUROPEENNE</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Vos données peuvent être transférées hors de l’Union européenne dans le cadre des outils que nous utilisons et de nos relations avec nos sous-traitants.</p>
                      <p>Ce transfert est sécurisé au moyen des outils suivants :</p>
                      <p className="p-left">- Soit ces données sont transférées dans un pays ayant été jugé comme oﬀrant un niveau de protection adéquat par une décision de la Commission Européenne ;</p>
                      <p className="p-left">- Soit nous avons conclu avec nos sous-traitants un contrat spéciﬁque encadrant les transferts de vos données en dehors de l’Union Européenne, sur la base des clauses contractuelles types entre un responsable de traitement et un sous-traitant approuvées par la Commission Européenne.</p>
                  </div>
                  <div>
                      <h3>ACCES, RECTIFICATION, LIMITATION ET EFFACEMENT DE VOS DONNEESS A CARACTERE PERSONNEL</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Conformément à la réglementation européenne en matière de protections des données à caractère personnel, vous bénéficiez des droits suivants:</p>
                      <p className="p-left">- Un droit d’accès vous permettant à tout moment de savoir si vos données personnelles sont ou ne sont pas traitées par nos services et lorsqu’elles le sont, d’avoir accès auxdites données personnelles et aux informations requises par la loi concernant les modalités de traitement de ces données,</p>
                      <p className="p-left">- Un droit de rectification vous permettant, de demander à ce que soient corrigées dans les meilleurs délais toute inexactitude concernant les données personnelles vous concernant,</p>
                      <p className="p-left">- Un droit à l’effacement, vous permettant de demander dans les meilleurs délais à ce que vos données personnelles soient effacées, sous réserve que cette demande d’effacement respecte les conditions requises par la loi applicable, </p>
                      <p className="p-left">- Un droit à la limitation du traitement de vos données personnelles, sous réserve que cette demande de limitation respecte les conditions requises par la loi applicable,</p>
                      <p className="p-left">- Un droit à la portabilité vous permettant de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, ou à demander que ces données personnelles soit transmises à un autre responsable de traitement, sous réserve que cette demande de portabilité respecte les conditions requises par la loi applicable,</p>
                      <p className="p-left">- Un droit d’opposition au traitement de vos données personnelles pour des raisons tenant à votre situation particulière sous réserve que cette opposition respecte les conditions requises par la loi applicable,</p>
                      <p className="p-left">- Le droit de retirer à tout moment le consentement spécifique donné à la collecte de vos données personnelles, notamment lorsque le traitement de vos données personnelles sert à des fins de prospection commerciale,</p>
                      <p className="p-left">- Le droit de définir des directives relatives à la conservation, à l&apos;effacement et à la communication de vos données à caractère personnel à compter de votre décès.</p>
                      <p>Vous avez le droit d’obtenir la limitation du traitement de vos données personnelles, dans les cas déﬁnis à l’article 18 du RGPD :</p>
                      <p className="p-left">- Pendant la durée de vériﬁcation que nous mettons en œuvre, lorsque vous contestez l’exactitude de vos données à caractère personnel,</p>
                      <p className="p-left">- Lorsque le traitement de ces données est illicite, et que vous souhaitez limiter ce traitement plutôt que supprimer vos données,</p>
                      <p className="p-left">- Lorsque nous n’avons plus besoin de vos données personnelles, mais que vous souhaitez leur conservation pour exercer vos droits,</p>
                      <p className="p-left">- Pendant la période de vériﬁcation des motifs légitimes, lorsque vous vous êtes opposés au traitement de vos données personnelles.</p>
                      <p>Il est rappelé aux personnes dont les données sont collectées sur le fondement de notre intérêt légitime, qu’elles peuvent à tout moment s&apos;opposer au traitement des données les concernant. Nous pouvons toutefois être amenés à poursuivre le traitement s’il existe des motifs légitimes pour le traitement qui prévalent sur vos droits et libertés ou si le traitement est nécessaire pour constater, exercer ou défendre nos droits en justice.</p>
                      <p>Vous pouvez vous désabonner de nos emails promotionnels via le lien fourni dans les emails. Même si vous choisissez de ne plus recevoir de messages promotionnels de notre part, vous continuerez à recevoir nos messages administratifs.</p>
                  </div>
                  <div>
                      <h3>PORTABILITE DE VOS DONNEES A CARACTERE PERSONNEL</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Vous disposez d’un droit à la portabilité des données à caractère personnel que vous nous aurez fournies, entendues comme les données que vous avez déclarées activement et consciemment dans le cadre de l’accès et de l’utilisation des services, ainsi que des données générées par votre activité dans le cadre de l’utilisation des services. </p>
                      <p>Nous vous rappelons que ce droit ne porte pas sur les données collectées et traitées sur une autre base légale que le consentement ou l’exécution du contrat nous liant.</p>
                      <p>Ce droit peut être exercé gratuitement, à tout moment, et notamment lors de la fermeture de votre compte sur le Site, aﬁn de récupérer et de conserver vos données à caractère personnel.</p>
                      <p>Dans ce cadre, nous vous adresserons vos données à caractère personnel, par tous moyens jugés utiles, dans un format ouvert standard couramment utilisé et lisible par informatique, conformément à l’état de l’art.</p>
                  </div>
                  <div>
                      <h3>INTRODUCTION D’UNE RECLAMATION DEVANT UNE AUTORITE DE CONTROLE</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Vous êtes également informés que vous disposez du droit d’introduire une réclamation auprès d’une autorité de contrôle compétente, (la Commission Nationale Informatique et Libertés pour la France), dans l’Etat membre dans lequel se trouve votre résidence habituelle, votre lieu de travail ou le lieu où la violation de vos droits aurait été commise, si vous considérez que le traitement de vos données à caractère personnel objet de la présente charte constitue une violation des textes applicables.</p>
                      <p>Ce recours pourra être exercé sans préjudice de tout autre recours devant une juridiction administrative ou juridictionnelle. En eﬀet, vous disposez également d’un droit à un recours administratif ou juridictionnel eﬀectif si vous considérez que traitement de vos données à caractère personnel objet de la présente charte constitue une violation des textes applicables.</p>
                  </div>
                  <div>
                      <h3>MODIFICATIONS</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Nous nous réservons le droit, à notre seule discrétion, de modiﬁer à tout moment la présente Charte en totalité ou en partie.</p>
                      <p>Ces modifications entreront en vigueur à compter de la publication de la nouvelle charte.</p>
                  </div>
              </div>
          </Grid>
      </PageWrapper>
  );
}
